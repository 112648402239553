import React from "react";
import img5 from "../images/regle-de-decoupe.jpg";
import img6 from "../images/bois-naturel.jpg";
import "./service.css";

/**Fonction js pour créer l'animation lors du scroll */
function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);
export default function Services() {
  return (
    <div className="reveal row  fade-bottom featurette ">
      <div className=" container-lg  ">
        <div
          class="container1 reveal row featurette p-5 " /**défile le contenu a gauche */
        >
          <hr class="featurette-divider" />

          <div class="col-md-7 order-md-2">
            <h2 class="featurette-heading1">Un savoir faire, une passion. </h2>

            <p class=" lead">
              Que ce soit artistes ou particuliers qui veulent mettre en valeur
              leurs oeuvres. Ma priorité est votre satisfaction.
            </p>
          </div>
          <div class="col-md-5 order-md-1">
            <img
              class="featurette-image img-fluid mx-auto"
              width="500px"
              height="500px"
              data-src="holder.js/500x500/auto"
              alt="500x500,encadrement,atelier,decoupe,bois"
              src={img5}
              data-holder-rendered="true"
            />
          </div>
        </div>

        <div class="container1 reveal  row  featurette  p-5">
          <div class="col-md-7 order-md-1">
            <h2 class="featurette-heading">
              Le travail <span class="text-muted">des matières</span>
            </h2>
            <p class="lead">
              A partir de matériaux naturels, du bois en passant par les vitres,
              je confectionne sur mesure vos encadrements. Un travail brut mais
              délicat.
            </p>
          </div>
          <div class="col-md-5 order-md-2">
            <img
              class="featurette-image img-fluid mx-auto"
              width="500px"
              height="500px"
              data-src="holder.js/500x500/auto"
              alt="500x500, bois, naturel,974,cadre"
              src={img6}
              data-holder-rendered="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
