import "./App.css";
import ContactPage from "./Components/pages/ContactPage";
import Home from "./Components/pages/Home";
import Galerie from "./Components/pages/Galerie";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Services from "./Components/pages/Services";
function App() {
  return (
    <>
      {" "}
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="galerie" element={<Galerie />} />
          <Route path="services" element={<Services />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
