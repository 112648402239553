import React from "react";
import "./footer.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
/**Partie openstreetmap */
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Importez le CSS de Leaflet
import L from "leaflet";
import customIconUrl from "../images/Logo-sophie974.jpg";
const customIcon = new L.Icon({
  iconUrl: customIconUrl,
  iconSize: [50, 50],
});
library.add(fab);
library.add(fas);

const Footer = () => {
  return (
    <footer className="bg-light text-black text-center py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h4>Contact</h4>

            <p>
              Adresse : 26 All. des Dattes, Pierrefonds, Saint-Pierre 97410, La
              Réunion
            </p>
            <p>Téléphone: 0692 81 14 77 </p>
            <p>sophie.d.borella@hotmail.fr</p>
          </div>
          <div className="col-md-4">
            <h4>Où me trouver ?</h4>
            <MapContainer
              center={[-21.301500724966207, 55.43034305458147]}
              zoom={16}
              style={{ height: "350px" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker
                position={[-21.301500724966207, 55.43034305458147]}
                icon={customIcon}
              >
                <Popup>
                  <div>
                    <h6>Adresse</h6>

                    <p>26 All. des Dattes, Saint-Pierre 97410, La Réunion </p>
                  </div>
                </Popup>
              </Marker>
            </MapContainer>
          </div>
          <div className="col-md-4">
            <ul className="link-social">
              <li className="Lien">
                <a href="https://www.instagram.com/les_cadres_de_sophie974/">
                  <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
                </a>
              </li>
              <li className="Lien">
                <a href="https://www.facebook.com/LescadresdeSophie974/">
                  <FontAwesomeIcon icon={["fab", "facebook"]} size="2x" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p className="droit">
        &copy; {new Date().getFullYear()} Les cadres de Sophie. Tous droits
        réservés. par Asson Sarah
      </p>

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center active"
      >
        <FontAwesomeIcon icon={["fas", "arrow-up"]} size="1x" />
      </a>
    </footer>
  );
};

export default Footer;
