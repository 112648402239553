import React from "react";
import img1 from "../../images/atelier-les-cadres-de-sophie-974.jpg";
import img2 from "../../images/decoupe-bois.jpg";
import img3 from "../../images/art-vitruvien.jpg";
import img4 from "../../images/encadrement-oeuvre.jpg";
import img5 from "../../images/les-Halles.jpg";
import img6 from "../../images/agraffeuse-cadre.jpg";
import img7 from "../../images/artiste-missy.jpg";
import img8 from "../../images/voitures-anciennes.jpg";
import img9 from "../../images/pandakroo.jpg";
import img10 from "../../images/maison-creole.jpg";
import img11 from "../../images/black-buttler.jpg";
import img12 from "../../images/chat-kung-fu.jpg";
import img13 from "../../images/amandine-urruty.jpg";
import img14 from "../../images/collier-encadrer.jpg";
import Navbar from "../Navbar";
import "./galerie.css";
import Footer from "../Footer";

export default function Galerie() {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="contain-galerie">
        <h1 className="galerie">Portfolio</h1>
      </div>
      <div class="row portfolio " /**Partie Portfolio */>
        <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
          <img
            src={img1}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="atelier les cadres de Sophie"
          />

          <img
            src={img7}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="tableau by missy"
          />
          <img
            src={img8}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="voitures colorées"
          />
          <img
            src={img13}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="cadres"
          />
        </div>

        <div class="col-lg-4 mb-4 mb-lg-0">
          <img
            src={img3}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="art contemporain"
          />
          <img
            src={img9}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="pandakroo encadrement"
          />

          <img
            src={img4}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="encadrement d'oeuvre"
          />
          <img
            src={img10}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="maisons creoles en peinture"
          />
          <img
            src={img14}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="collier encadré"
          />
        </div>

        <div class="col-lg-4 mb-4 mb-lg-0">
          <img
            src={img5}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="peinture d'un vieux marché"
          />
          <img
            src={img6}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="agraffe de tableau"
          />{" "}
          <img
            src={img2}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="coupe de bois pour encadrement"
          />
          <img
            src={img11}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="blackbuttler cadre avec passe-partout"
          />
          <img
            src={img12}
            class="w-100 shadow-1-strong rounded mb-4"
            alt="chat kung-fu"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
