import React from "react";
import img1 from "../../images/bois-vrac.jpg";
import img2 from "../../images/decoupe-vitre.jpg";
import img3 from "../../images/passe-partout.jpg";
import img4 from "../../images/couleur-bois.jpg";
import Navbar from "../Navbar";

import Footer from "../Footer";
import "./services.css";
export default function Services() {
  return (
    <div>
      <Navbar />
      <section class="features003 section-container" id="features003-3">
        <div class="container">
          <div class="row">
            <div class="col-12 align-left mb-2">
              <h2 class="section-big-title pb-4 mb-0 mbr-fonts-style display-2">
                Personnalisation sur-mesure.
              </h2>
            </div>
            <div class="col-md-6 col-12 align-left features-image item">
              <div class="item-wrapper">
                <div class="item-img">
                  <img
                    className="img-service"
                    src={img1}
                    alt="bois naturel pour encadrement"
                  />
                </div>
                <div class="card item-content">
                  <h5 class="mbr-section-title mbr-semibold mbr-black mbr-fonts-style display-5">
                    Bois naturel
                  </h5>
                  <p class="mbr-text mbr-black mbr-regular mbr-fonts-style display-4">
                    Le bois est importé de métropole. Retrouvez une sélection de
                    bois directement en atelier, avec possibilité de commander
                    un plus large choix.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12 align-left features-image item">
              <div class="item-wrapper">
                <div class="item-img">
                  <img
                    className="img-service"
                    src={img2}
                    alt="vitre découpée manuellement"
                  />
                </div>
                <div class="card item-content">
                  <h5 class="mbr-section-title mbr-semibold mbr-black mbr-fonts-style display-5">
                    Vitre de qualité
                  </h5>
                  <p class="mbr-text mbr-black mbr-regular mbr-fonts-style display-4">
                    Les vitres sont de 3mm d'épaisseur, coupées sur mesure
                    directement en atelier. Elles vous garantissent une
                    protection optimale dans le temps.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12 align-left features-image item">
              <div class="item-wrapper">
                <div class="item-img">
                  <img
                    className="img-service"
                    src={img3}
                    alt="passe-partout pour oeuvre d'art"
                  />
                </div>
                <div class="card item-content">
                  <h5 class="mbr-section-title mbr-semibold mbr-black mbr-fonts-style display-5">
                    Passe-partout
                  </h5>
                  <p class="mbr-text mbr-black mbr-regular mbr-fonts-style display-4">
                    Afin d'éviter le contact direct avec la vitre, le
                    passe-partout vous garantit une sécurité entre l'oeuvre et
                    la vitre. Ainsi qu'une mise en valeur en donnant de la
                    profondeur à l'oeuvre.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12 align-left features-image item">
              <div class="item-wrapper">
                <div class="item-img">
                  <img
                    className="img-service"
                    src={img4}
                    alt="bois colorés pour personnaliser un cadre"
                  />
                </div>
                <div class="card item-content">
                  <h5 class="mbr-section-title mbr-semibold mbr-black mbr-fonts-style display-5">
                    Couleurs personnalisées
                  </h5>
                  <p class="mbr-text mbr-black mbr-regular mbr-fonts-style display-4">
                    Que ce soit du papier à la peinture, du bois au
                    passe-partout,vous personnalisez complètement vos
                    encadrements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
