import React from "react";
import img0 from "../images/Logo-sophie974.jpg";
import "./navbar.css";

const Navbar = () => {
  return (
    <nav
      class="navbar navbar-expand-lg navbar-dark bg-dark"
      aria-label="Tenth navbar example"
    >
      <div class="container-fluid">
        <button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExample08"
          aria-controls="navbarsExample08"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <a href="/">
          <img
            className="rounded-circle"
            src={img0}
            alt="les cadres de sophie"
            width="50px"
            height="50px"
          />
        </a>
        <div
          class="navbar-collapse justify-content-md-center collapse"
          id="navbarsExample08"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/">
                Accueil
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link " href="/services">
                Services
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link " href="/galerie">
                Portfolio
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link " href="/contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
