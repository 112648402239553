import React from "react";
import img5 from "../images/decoupe-bois.jpg";
import img6 from "../images/bombe-peinture.jpg";
import img9 from "../images/atelier-de-sophie.jpg";
import img10 from "../images/bois-naturel.jpg";
import img11 from "../images/regle-de-decoupe.jpg";
import img12 from "../images/agraffeuse-cadre.jpg";
import "./portfolio.css";

export default function Portfolio() {
  return (
    <div className="container-lg">
      <h1 class="txt-titre text-center">Portfolio</h1>
      <section className="card-container">
        <div class="row gy-4 row-cols-1 row-cols-sm-2 row-cols-md-3">
          <div class="col">
            <div
              class="bg-image hover-overlay shadow-1-strong rounded ripple"
              data-mdb-ripple-color="light"
            >
              <img
                src={img5}
                class="img-animation img-fluid"
                alt="découpe, bois,art"
              />
            </div>
          </div>
          <div class="col">
            <div
              class=" bg-image hover-overlay shadow-1-strong rounded ripple"
              data-mdb-ripple-color="light"
            >
              <img
                src={img9}
                class="img-animation img-fluid"
                alt="atelier, 974, île de la reunion, artisan"
              />
            </div>
          </div>
          <div class="col">
            <div
              class="bg-image hover-overlay shadow-1-strong rounded ripple"
              data-mdb-ripple-color="light"
            >
              <img
                src={img11}
                class="img-animation img-fluid"
                alt="spray, art, artiste"
              />
            </div>
          </div>
        </div>
        <div
          class="row sec-colonne gy-4 row-cols-1 row-cols-sm-2 row-cols-md-3"
          id="img-mobile"
        >
          <div class="col">
            <div
              class="bg-image hover-overlay shadow-1-strong rounded ripple"
              data-mdb-ripple-color="light"
            >
              <img src={img6} class=" img-animation img-fluid" />
            </div>
          </div>
          <div class="col">
            <div
              class="bg-image hover-overlay shadow-1-strong rounded ripple"
              data-mdb-ripple-color="light"
            >
              <img src={img12} class="img-animation img-fluid" />
            </div>
          </div>
          <div class="col">
            <div
              class="bg-image hover-overlay shadow-1-strong rounded ripple"
              data-mdb-ripple-color="light"
            >
              <img src={img10} class=" img-animation img-fluid" />
            </div>
          </div>
        </div>
        <div
          id="btn-galerie"
          class="jQShowMoreWrapper for_topic__question__more" /**Bouton pour afficher la suite des élèments */
        >
          <a class="nav-link " href="/galerie">
            <button class="ico--chevron-down btn--border jQShowMoreBtn"></button>
          </a>
        </div>
      </section>
    </div>
  );
}
