import React from "react";
import img2 from "../images/atelier-de-sophie.jpg";
import "./description.css";
export default function Description() {
  return (
    <div class="container-desc  " /**défile le contenu a gauche */>
      <div class="container text-center mx-auto p-5">
        <div class="desc-contain row">
          <div class=" tiret col align-self-start">
            <img
              class="featurette-image img-fluid img-desc "
              data-src="holder.js/500x500/auto"
              alt="500x500, atelier les cadres de sophie à la Réunion"
              src={img2}
              data-holder-rendered="true"
            />
          </div>

          <div class="col align-self-center">
            <h2 class="txt-desc featurette-heading-desc">
              Votre artisan encadreur{" "}
              <span class="text-muted">Sophie Borella</span>
            </h2>

            <p class="lead">
              Un atelier d’encadrement simple et convivial. Tous travaux
              acceptés: tableaux, posters, dessins...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
// <div className="row">
//   <h1>Sophie, artisan encadreur</h1>
//   <hr class="featurette-divider" />
//   <div class="txt-mid col-md-4 order-md-2" /**texte du milieu */>
//     <h2 class="featurette-heading">
//       Oh yeah, it's that good.{" "}
//       <span class="text-muted">See for yourself.</span>
//     </h2>

//     <p class="lead">
//       Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id
//       ligula porta felis euismod semper. Praesent commodo cursus magna,
//       vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus
//       commodo. Donec ullamcorper nulla non metus auctor fringilla.
//       Vestibulum id ligula porta felis euismod semper. Praesent commodo
//       cursus magna, vel scelerisque nisl consectetur. Fusce dapibus,
//       tellus ac cursus commodo. Donec ullamcorper nulla non metus auctor
//       fringilla. Vestibulum id
//     </p>
//   </div>
//   <div class="col-md-3 order-md-3" /**3eme article */>
//     <h2 class="featurette-heading">
//       Oh yeah, it's that good.{" "}
//       <span class="text-muted">See for yourself.</span>
//     </h2>

//     <p class="lead">
//       Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id
//       ligula porta felis euismod semper. Praesent commodo cursus magna,
//       vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus
//       commodo.
//     </p>
//     <img
//       class="featurette-image img-fluid "
//       data-src="holder.js/500x500/auto"
//       alt="500x500"
//       src={img2}
//       data-holder-rendered="true"
//     />
//   </div>
//   <div class="img-left col-md-4 order-md-1" /**img à gauche du texte */>
//     <img
//       class="img-desc featurette-image img-fluid "
//       data-src="holder.js/500x500/auto"
//       alt="500x500"
//       src={img2}
//       data-holder-rendered="true"
//     />
//   </div>
// </div>
