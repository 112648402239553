import React from "react";
import img3 from "../images/tas-cadres.jpg";
import img4 from "../images/couleur-bois.jpg";
import img5 from "../images/passe-partout-blanc.jpg";
import "./savoirfaire.css";

/**Fonction js pour créer l'animation lors du scroll */
function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

export default function Card() {
  return (
    <div class=" container-lg mt-5 mb-5 album py-5 bg-light">
      <div class=" container ">
        <div
          class=" container  row" /**La classe : reaveal active l'animation lors du scroll */
        >
          <div class="card-animation col-md-4 ">
            <div class="card mb-4 box-shadow border-0">
              {" "}
              <img
                class="card-img-top"
                src={img3}
                data-holder-rendered="true"
                alt="cadres,art,sur-mesure"
              />{" "}
              <h2 className="serv-txt">Cadres sur mesure</h2>
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-animation col-md-4">
            <div class="card mb-4 box-shadow border-0">
              <img
                class="card-img-top"
                src={img4}
                data-holder-rendered="true"
                alt="bois,cadres,artistique,couleur,art"
              />
              <h2 className="serv-txt">Personnalisez votre couleur</h2>
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-animation col-md-4">
            <div class="card mb-4 box-shadow border-0">
              <img
                class="card-img-top"
                src={img5}
                data-holder-rendered="true"
                alt="passe-partout,cadres,art"
              />
              <h2 className="serv-txt">Passe-partout</h2>
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group"></div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div
          class="jQShowMoreWrapper for_topic__question__more" /**Bouton pour afficher la suite des élèments */
        >
          <a class="nav-link " href="/services">
            <button class=" btn--border-service jQShowMoreBtn">
              En savoir plus{" "}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
//  <div class="column p-2 g-col-6 ">
//         <div class="col-md-8 mb-4">
//           <div
//             class="bg-image hover-overlay shadow-1-strong rounded ripple"
//             data-mdb-ripple-color="light"
//           >
//             <img src={img3} class="img-fluid" width="90%" height="70%" />
//           </div>
//         </div>

//         <div class="col-md-8 mb-4">
//           <h5>Very long post title</h5>
//           <p>
//             Lorem ipsum dolor sit amet consectetur adipisicing elit.
//             Voluptatibus ratione necessitatibus itaque error alias repellendus
//             nemo reiciendis aperiam quisquam minus ipsam reprehenderit commodi
//             ducimus, in dicta aliquam eveniet dignissimos magni.
//           </p>
//         </div>
//       </div>

//       <div class="column p-2 g-col-6">
//         <div class=" col-md-8 mb-4">
//           <div
//             class="bg-image hover-overlay shadow-1-strong rounded ripple"
//             data-mdb-ripple-color="light"
//           >
//             <img src={img3} class="img-fluid" width="90%" height="70%" />
//           </div>
//         </div>

//         <div class="col-md-8 mb-4">
//           <h5>Very long post title</h5>
//           <p>
//             Lorem ipsum dolor sit amet consectetur adipisicing elit.
//             Voluptatibus ratione necessitatibus itaque error alias repellendus
//             nemo reiciendis aperiam quisquam minus ipsam reprehenderit commodi
//             ducimus, in dicta aliquam eveniet dignissimos magni.
//           </p>
//         </div>
//       </div>
//       <div class="column p-2 g-col-6">
//         <div class=" col-md-8 mb-4">
//           <div
//             class="bg-image hover-overlay shadow-1-strong rounded ripple"
//             data-mdb-ripple-color="light"
//           >
//             <img src={img3} class="img-fluid" width="90%" height="70%" />
//           </div>
//         </div>

//         <div class="col-md-8 mb-4">
//           <h5>Very long post title</h5>
//           <p>
//             Lorem ipsum dolor sit amet consectetur adipisicing elit.
//             Voluptatibus ratione necessitatibus itaque error alias repellendus
//             nemo reiciendis aperiam quisquam minus ipsam reprehenderit commodi
//             ducimus, in dicta aliquam eveniet dignissimos magni.
//           </p>
//         </div>
//       </div>
//       <div class="column p-2 g-col-6">
//         <div class=" col-md-8 mb-4">
//           <div
//             class="bg-image hover-overlay shadow-1-strong rounded ripple"
//             data-mdb-ripple-color="light"
//           >
//             <img src={img3} class="img-fluid" width="90%" height="70%" />
//           </div>
//         </div>

//         <div class="col-md-8 mb-4">
//           <h5>Very long post title</h5>
//           <p>
//             Lorem ipsum dolor sit amet consectetur adipisicing elit.
//             Voluptatibus ratione necessitatibus itaque error alias repellendus
//             nemo reiciendis aperiam quisquam minus ipsam reprehenderit commodi
//             ducimus, in dicta aliquam eveniet dignissimos magni.
//           </p>
//         </div>
//       </div>*/
