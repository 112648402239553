import React from "react";
import "./citation.css";
import img11 from "../images/peinture-manga.jpg";

export default function Citation() {
  return (
    <section id="call-to-action" class="call-to-action">
      <img src={img11} alt="cadres,bois,naturel,gris,citation"></img>
      <div class="container-citation">
        <div
          class="row justify-content-center aos-init aos-animate"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div class="col-xl-10">
            <div class="text-center">
              <h3 className="txt-creatif">La créativité</h3>

              <p className="txt-crea">C'est l'intelligence qui s'amuse</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
