import React from "react";
import "./header.css";
import img1 from "../images/les-cadres-de-sophie.jpg";
export default function Header() {
  return (
    <section id="header-scroll" class="header-scroll">
      <img src={img1} alt="cadres,bois,naturel,gris,citation"></img>
      <div class="container-citation">
        <div
          class="row justify-content-center aos-init aos-animate"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div class="col-xl-10">
            <div class="text-center">
              <h3 className="txt-creatif-header">Les cadres de Sophie</h3>

              <p className="txt-crea-header">Artisan encadreur de la Réunion</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
// <a href="#about" class="btn-scroll scrollto" title="Scroll Down">
//       <FontAwesomeIcon
//         className="bx bx-chevron-down"
//         icon={["fab", "arrow"]}
//         size="2x"
//       />
//     </a>
//  <section id="hero">
//       <div class="hero-container">
//         <h1>Les cadres de Sophie</h1>
//         <h2>Artisan encadreur de la Réunion</h2>
//       </div>
//     </section>
