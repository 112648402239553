import React from "react";
import "../pages/contactpage.css";
import Navbar from "../../Components/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
/**Partie openstreetmap */
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Importez le CSS de Leaflet
import L from "leaflet";
import customIconUrl from "../../images/Logo-sophie974.jpg";
const customIcon = new L.Icon({
  iconUrl: customIconUrl,
  iconSize: [50, 50],
});
library.add(fab);

export default function Contact() {
  return (
    <div>
      <Navbar />
      <div class=" contact-container ">
        <div class="left-col"></div>
        <div class="right-col">
          <div class="contact-wrap w-100 p-md-5 p-4">
            <h3>Contactez moi</h3>
            <p class="mb-4">
              Horaire d'ouverture:
              <br></br>
              <br></br>
              Du mardi au samedi : 9:30-12:00, 14:00-17:00
            </p>

            <div class="row mb-4">
              <div class="col-md-4">
                <div class="dbox w-100 d-flex align-items-start">
                  <div class="text">
                    <p>
                      <span>Adresse:</span> 26 All. des Dattes, Pierrefonds,
                      Saint-Pierre 97410, La Réunion
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="dbox w-100 d-flex align-items-start">
                  <div class="text">
                    <p>
                      <span>Email:</span>{" "}
                      <a href="mailto:info@yoursite.com">
                        sophie.d.borella@hotmail.fr
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="dbox w-100 d-flex align-items-start">
                  <div class="text">
                    <p>
                      <span>Téléphone:</span>{" "}
                      <a href="tel://1234567920">0692 81 14 77</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <MapContainer
                center={[-21.301500724966207, 55.43034305458147]}
                zoom={16}
                style={{ height: "350px" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker
                  position={[-21.301500724966207, 55.43034305458147]}
                  icon={customIcon}
                >
                  <Popup>
                    <div>
                      <h6>Adresse</h6>
                      <p>
                        26 All. des Dattes, Pierrefonds, Saint-Pierre 97410, La
                        Réunion
                      </p>
                    </div>
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
            <div class="w-100 social-media mt-5 ">
              <h3>Suivez-moi sur: </h3>

              <p className="social-link-contact">
                <a href="https://www.instagram.com/les_cadres_de_sophie974/">
                  {" "}
                  <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
                </a>

                <a href="https://www.facebook.com/LescadresdeSophie974/">
                  <FontAwesomeIcon icon={["fab", "facebook"]} size="2x" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
