import React from "react";
import img4 from "../images/espece.png";
import img5 from "../images/virement-1.png";
import img6 from "../images/paiement-securise.png";
import "./paiement.css";
export default function Portfolio() {
  return (
    <div class="container-lg mt-5 mb-5 reveal fade-bottom container marketing">
      <h1 className="Titre">Moyens de paiement</h1>{" "}
      <div class="row">
        <div class="col-lg-4">
          {" "}
          <img
            class="rounded-circle"
            src={img6}
            alt="Generic placeholder image paiement"
            width="150"
            height="150"
          />
          <h2 className="serv-txt">Paiement par carte bancaire</h2>
        </div>
        <div class="col-lg-4">
          <img
            class="rounded-circle"
            src={img4}
            alt="Generic placeholder image paiement espece"
            width="150"
            height="150"
          />
          <h2 className="serv-txt">Chèques/Especes</h2>
        </div>
        <div class="col-lg-4">
          <img
            class="rounded-circle"
            src={img5}
            alt="Generic placeholder image"
            width="150"
            height="150"
          />
          <h2 className="serv-txt">Virement</h2>
        </div>
      </div>
      <hr class="featurette-divider" />
      <div class="row featurette"></div>
    </div>
  );
}
