import React from "react";
import Navbar from "../Navbar";
import Header from "../Header";
import Description from "../Description";

import Footer from "../Footer";
import Paiement from "../Paiement";
import Portfolio from "../Portfolio";
import Savoirfaire from "../Service";
import Service from "../Savoirfaire";
import "bootstrap/dist/css/bootstrap.min.css";
import Citation from "../Citation";

function Home() {
  return (
    <div>
      <Navbar />
      <Header />
      <Description />

      <Service />
      <Citation />
      <Savoirfaire />

      <Paiement />
      <Portfolio />
      <Footer />
    </div>
  );
}

export default Home;
